import {
  Address,
  IncorrectEnvironmentVariableError,
  MissingEnvironmentVariableError,
  NativeCurrency,
} from '@ttx/core';
import nookies from 'nookies';
import {
  AUTHENTICATED_WALLET_STATE_COOKIE_NAME,
  AUTHENTICATED_WALLET_ID_STATE,
  AUTHENTICATED_WALLET_INFO_STATE,
  AUTHENTICATED_SIGNATURE_STATE,
} from '../../server/cookies/consts';
import {
  chainConfig,
  chainNames,
  ethereumRpc,
  polygonRpc,
  registrationSources,
  zilliqaRpc,
} from './consts';
import {
  LoggedWalletInfo,
  WalletNetwork,
  WalletRegistrationSource,
  WalletState,
  Web3Balance,
} from './types';

const Web3 = require('web3');

export const sanitizeLoadedWalletState = (
  loadedWalletState: unknown,
): WalletState | null => {
  if (typeof loadedWalletState !== 'object') return null;

  const typedWalletState = loadedWalletState as Partial<WalletState>;

  if (
    !typedWalletState.address ||
    typeof typedWalletState.address !== 'string' ||
    !typedWalletState.address.startsWith('0x')
  )
    return null;

  if (
    !typedWalletState.provider ||
    !registrationSources.includes(typedWalletState.provider)
  )
    return null;

  if (!typedWalletState.chainId || typeof typedWalletState.chainId !== 'number')
    return null;

  return typedWalletState as WalletState;
};

export const getAuthenticatedWalletState = (): WalletState | null => {
  const stringifiedWalletState =
    nookies.get()[AUTHENTICATED_WALLET_STATE_COOKIE_NAME];

  if (!stringifiedWalletState) return null;

  const loadedWalletState = JSON.parse(stringifiedWalletState);

  return sanitizeLoadedWalletState(loadedWalletState);
};

export const getAuthenticatedWalletIdState = (): string | null => {
  const walletIdState = nookies.get()[AUTHENTICATED_WALLET_ID_STATE];

  return walletIdState || null;
};

export const getAuthenticatedSignatureState = (): string | null => {
  const signatureState = nookies.get()[AUTHENTICATED_SIGNATURE_STATE];

  return signatureState || null;
};

export const getAuthenticatedWalletInfoState = (): LoggedWalletInfo | null => {
  const walletInfoState = nookies.get()[AUTHENTICATED_WALLET_INFO_STATE];

  return walletInfoState ? JSON.parse(walletInfoState) : null;
};

export const clearAuthenticatedWalletInfoState = (): void => {
  nookies.destroy(undefined, AUTHENTICATED_WALLET_INFO_STATE);
};

export const clearAuthenticatedWalletState = (): void => {
  nookies.destroy(undefined, AUTHENTICATED_WALLET_STATE_COOKIE_NAME);
};

export const networkNameToSymbol = (
  networkName: WalletNetwork,
): NativeCurrency => {
  switch (networkName) {
    case 'ethereum':
      return 'ETH';
    case 'polygon':
      return 'MATIC';
    case 'zilliqa':
      return 'ZIL';
    default:
      throw new Error(`Unknown network name: ${networkName}`);
  }
};

const getChainIdFromEnvironmentVariable = (
  environmentVariableName: keyof typeof chainConfig,
  allowedChainIds: (number | string)[],
): number | string => {
  const chainIdString = chainConfig[environmentVariableName] as
    | number
    | undefined;
  if (!chainIdString)
    throw new MissingEnvironmentVariableError(environmentVariableName);

  const chainId = Number.isNaN(Number(chainIdString))
    ? chainIdString
    : Number(chainIdString);

  if (!allowedChainIds.includes(chainId))
    throw new IncorrectEnvironmentVariableError(
      environmentVariableName,
      chainId,
      allowedChainIds,
    );

  return chainId;
};

export const getPolygonChainId = (): number =>
  Number(
    getChainIdFromEnvironmentVariable(
      'POLYGON_CHAIN',
      Object.keys(polygonRpc).map(Number),
    ),
  );

export const getEthereumChainId = (): number =>
  Number(
    getChainIdFromEnvironmentVariable(
      'ETHEREUM_CHAIN',
      Object.keys(ethereumRpc).map(Number),
    ),
  );

export const getZilliqaChainId = (): number => {
  const chainId = getChainIdFromEnvironmentVariable(
    'ZILLIQA_CHAIN',
    Object.values(zilliqaRpc),
  );
  return Number(
    Object.entries(zilliqaRpc)
      .filter(([, value]) => value === chainId)
      .map(([key]) => key)[0],
  );
};

export const getNetworkFromChainId = (
  chainId: number,
  registrationSource: WalletRegistrationSource,
): WalletNetwork | null => {
  switch (registrationSource) {
    case 'zilPay':
      if (chainId in zilliqaRpc) return 'zilliqa';
      return null;
    default:
      if (chainId in ethereumRpc) return 'ethereum';
      if (chainId in polygonRpc) return 'polygon';
      return null;
  }
};

export const getFormattedChainIdErrorMessage: (
  supportedChains?: string[],
  message?: string,
) => string | null = (supportedChains, message) => {
  try {
    if (!message || !supportedChains) throw new Error();
    const invalidChainId = message
      .split(':')[1]
      .split('.')[0]
      .trim() as unknown as keyof typeof chainNames;
    const invalidChainName = chainNames?.[invalidChainId];
    const supportedChainNames = supportedChains
      ?.map((chain, i) =>
        i === supportedChains.length - 1 ? ` or ${chain}.` : ` ${chain}`,
      )
      .toString();

    if (!invalidChainName || !supportedChainNames)
      return `Invalid chain. Try connecting to another chain.`;
    return `${invalidChainName} is not supported. Try connecting to${supportedChainNames}`;
  } catch (err) {
    return null;
  }
};

export const getDeeplinkUrl = (isMobile: boolean): string => {
  if (!isMobile) {
    return `https://metamask.io/download/`;
  }
  return `https://metamask.app.link/dapp/${window.location.href}/`;
};

export const getEVMChainBalance = async (
  walletAddress: Address,
  rpc: string,
) => {
  const provider = new Web3.providers.HttpProvider(rpc);
  const web3: Web3Balance = new Web3(provider);
  const balance = await web3.eth.getBalance(walletAddress);
  return balance || '0';
};
