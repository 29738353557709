export const POYLMESH_EXTN_NAME = 'polywallet';
export const APP_NAME = 'TokenTraxx';
export const TESTNET_CHAIN = 99999; // this is set by us for our purpose; there is no chain ID with this type
export const MAINNET_CHAIN = 999; // this is set by us for our purpose; there is no chain ID with this type

export const TICKER = '';

// MAINNET
export const whitelistedArtistList = [
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1695133330-evan-430x430-profile.png',
    artistName: 'Evan Malamud',
    // id: '65d894224fea2479bd67df66',
    id: '0xeff44c144dc22f9b0b631c051ce9b3810617761112f5a81d49ede0c12c1804af',
    numOfCollections: 1,
    slug: 'ROMEOXJULIET',
    portfolio: 'traxx x evan',
  },
];
