/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React, {useEffect, useState} from 'react';
import {Typography} from '../typography';
import {Box} from '../box';
import {CommonNavbarProps} from './types';
import {FrameworkLink} from '../link-renderer';
import {TransparentButton} from '../button/transparent-button';
import {ContentContainerBox} from '../content-container-box';
import {NavBarInfo} from './navbar-info';
import {
  footerSocialURLs,
  matchWithRoute,
  NavBarSocialLinks,
} from './navbar-block-constants';
import {Button} from '../button/button';
import {FrameworkImage} from '../image-renderer';
import {Pressable} from '../pressable';
// import {CopyToClipboard as BaseCopyToClipboard} from 'react-copy-to-clipboard';
import {CopyIcon} from '../icons';

import {usePolymesh} from '../../../../apps/web/contexts/polymesh-context';
import {
  LocalStroageUtil,
  POLYMESH_LOCAL_STORAGE,
} from '../../../../apps/web/utils/local-storage-utils';
import {Identity} from '@polymeshassociation/polymesh-sdk/internal';

export interface DesktopNavbarProps extends CommonNavbarProps {
  runLogoAnimation: boolean;
}

const STORED_HOVER_STATE = {
  IS_HOVERED: false,
};

interface IAccount {
  name: string;
  key: string;
  address: string;
}

export const isActiveNavRouteUtil = (
  currentHref: string,
  subRoutes: string[],
) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < subRoutes.length; i++) {
    if (currentHref.indexOf(subRoutes[i]) > -1) {
      return true;
    }
  }
  return false;
};

export const DesktopNavbar: React.FC<DesktopNavbarProps> = ({
  items,
  currentHref,
  homePageHref = '#',
  walletConnected,
  openWallet,
  openPurchaseHistory,
  runLogoAnimation,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedKeyName, setSelectedKeyName] = useState<string>();

  const {
    allAccountsWithMeta,
    setSelectedAccount,
    selectedAccount,
    identity,
    identityLoading,
    allIdentities,
    allAccounts,
  } = usePolymesh();

  useEffect(() => {
    if (!identityLoading) {
      LocalStroageUtil.setItem(POLYMESH_LOCAL_STORAGE.DID, identity?.did);
    }
  }, [identity]);

  useEffect(() => {
    if (!selectedAccount) {
      setSelectedKeyName('');
      return;
    }

    const keyName = allAccountsWithMeta.find(
      ({address}) => address === selectedAccount,
    )?.meta.name;
    setSelectedKeyName(keyName || '');
  }, [selectedAccount, allAccountsWithMeta]);

  const truncateString = (str: string) => {
    if (str.length <= 10) return str;
    const truncatedStr = str.slice(0, 5) + '...' + str.slice(-5);
    return truncatedStr;
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const CopyToClipboard = (value: string) => (
    <div onClick={handleClick} role="presentation">
      {/* <BaseCopyToClipboard text={value as string} onCopy={() => {}}> */}
        <Box cursor="pointer" marginLeft="two">
          <CopyIcon size={15} />
        </Box>
      {/* </BaseCopyToClipboard> */}
    </div>
  );

  return (
    <ContentContainerBox zIndex={2}>
      <Box
        width="100%"
        flexDirection="row"
        borderStyle="solid"
        height="70px"
        borderColor="outlines"
        bg="backgroundBlur"
        borderWidth="one"
        css={{
          'backdrop-filter': 'blur(50px)',
          'border-width': '1px',
        }}
      >
        <Box flex="1" flexDirection="row" alignItems="center">
          <FrameworkLink href={`/polymesh/artists`}>
            <Box
              w={112}
              ml="twelve"
              height="100%"
              justifyContent="center"
              mr="twelve"
              position="relative"
            >
              <Box height="16px" width="108px">
                <FrameworkImage
                  src="/logo-tt-text.png"
                  alt="background"
                  layout="fill"
                />
              </Box>
              {currentHref === '/' && (
                <Box
                  borderBottomColor="outlines"
                  borderBottomStyle="solid"
                  borderBottomWidth="two"
                  position="absolute"
                  top="0px"
                  left="0px"
                  style={{height: '100%', width: '100%'}}
                />
              )}
              {/* <TTLogoAnimation runAnimation={runLogoAnimation} /> */}
            </Box>
          </FrameworkLink>
          {items.map((item, i) =>
            item && typeof item === 'object' && 'href' in item ? (
              <>
                <FrameworkLink
                  key={`${item.text}${item.href}`}
                  href={item.href}
                >
                  <TransparentButton
                    key={`${item.text}${item.href}`}
                    onPress={() => null}
                  >
                    <Box
                      paddingX="three"
                      paddingY="six"
                      position="relative"
                      borderBottomColor="outlines"
                      borderBottomStyle="solid"
                      onHoverIn={() => {
                        item.handleHover && item.handleHover();
                        STORED_HOVER_STATE.IS_HOVERED = true;
                        setIsHovered(true);
                      }}
                      onHoverOut={() => {
                        STORED_HOVER_STATE.IS_HOVERED = false;
                        setTimeout(() => {
                          if (!STORED_HOVER_STATE.IS_HOVERED) {
                            setIsHovered(false);
                          }
                        }, 500);
                      }}
                      borderBottomWidth="zero"
                    >
                      <Typography color="textDefault" textStyle="s">
                        {item.text}
                      </Typography>
                      {currentHref &&
                        item?.subRoutes &&
                        isActiveNavRouteUtil(currentHref, item.subRoutes) && (
                          <Box
                            borderBottomColor="outlines"
                            borderBottomStyle="solid"
                            borderBottomWidth="two"
                            position="absolute"
                            top="1px"
                            left="0px"
                            style={{height: '100%', width: '100%'}}
                          />
                        )}
                    </Box>
                  </TransparentButton>
                </FrameworkLink>
              </>
            ) : (
              <Box height="100%" justifyContent="center" key={i.toString()}>
                <>{item}</>
              </Box>
            ),
          )}
        </Box>

        <Box flexDirection="column">
          {currentHref === '/' ||
          (currentHref as string).includes('/polymesh/') ||
          (currentHref && matchWithRoute(currentHref)) ? (
            <Box flexDirection="row" alignItems="center">
              {/* <Box
                marginTop="two"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography color="textDefault">
                  <div id="address">
                    {truncateString(selectedAccount?.address || 'NA')}
                  </div>
                </Typography>
                {selectedAccount?.address && (
                  <>{CopyToClipboard(selectedAccount?.address)}</>
                )}
              </Box> */}
              {/* <select
                onChange={async (e) => {
                  setSelectedAccount(e.target.value);
                }}
              >
                {allAccountsWithMeta.map(({address}) => (
                  <React.Fragment>
                    <option value={address}>{address}</option>
                  </React.Fragment>
                ))}
              </select> */}
              <Box marginLeft="fourteen" right="eight" marginTop="three">
                <Box flexDirection="row" justifyContent="flex-end">
                  <Button
                    hasBorder={false}
                    type="primary"
                    onPress={() => setShowDropdown(!showDropdown)}
                    style={{
                      width: '10em',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    // loading={identityLoading}
                  >
                    {selectedKeyName}
                  </Button>
                </Box>
                {showDropdown && (
                  <Box
                    borderColor="outlines"
                    borderWidth="one"
                    borderRadius="two"
                    borderStyle="solid"
                    marginTop="one"
                    zIndex={2}
                    bg="surfaceBlack"
                    position="absolute"
                    right="0px"
                    top="40px"
                  >
                    {allAccountsWithMeta.map(({address, meta}, k) => (
                      <Pressable
                        onPress={() => {
                          // setSelectedAccount(account);
                          setShowDropdown(false);
                          setSelectedKeyName(meta.name);
                          setSelectedAccount(address);
                        }}
                        key={k}
                      >
                        <Box
                          width="100%"
                          borderBottomColor="grey24"
                          borderBottomWidth="one"
                          borderBottomStyle="solid"
                          padding="three"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box marginRight="eighteen">
                            <div>
                              <Typography color="textDefault" fontSize="m">
                                {meta.name}
                              </Typography>
                            </div>
                            <div>
                              <Typography color="textDefault" opacity={0.9}>
                                {truncateString(address)}
                              </Typography>
                            </div>
                          </Box>
                          {/* <Box
                            backgroundColor={
                              selectedAccount?.key === account.key
                                ? 'brandPrimary'
                                : 'surfaceBlack'
                            }
                            opacity={0.9}
                            padding="two"
                            borderRadius="two"
                          >
                            <Typography
                              color={
                                selectedAccount?.key === account.key
                                  ? 'textInvert'
                                  : 'textDefault'
                              }
                            >
                              Primary
                            </Typography>
                          </Box> */}
                        </Box>
                      </Pressable>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <>
              {!walletConnected && (
                <Box marginLeft="two" right="eight">
                  <Button hasBorder={false} type="primary" onPress={openWallet}>
                    Connect wallet
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {isHovered && (
        <Box
          zIndex="1"
          backgroundColor="outlinesInvert"
          marginLeft="one"
          marginRight="one"
          borderBottomColor="outlines"
          borderBottomWidth="one"
          borderBottomStyle="solid"
          onHoverIn={() => {
            STORED_HOVER_STATE.IS_HOVERED = true;
            setIsHovered(true);
          }}
          onHoverOut={() => {
            STORED_HOVER_STATE.IS_HOVERED = false;
            setTimeout(() => {
              if (!STORED_HOVER_STATE.IS_HOVERED) {
                setIsHovered(false);
              }
            }, 500);
          }}
        >
          <NavBarInfo footerSocialLinks={footerSocialURLs} />
        </Box>
      )}
    </ContentContainerBox>
  );
};
