import {
  Box,
  styled,
  DiscordIcon,
  Footer,
  MediumIcon,
  Navbar,
  NavbarItem,
  TelegramIcon,
  TwitterIcon,
  useSpring,
  VisibilityBox,
  CookieBanner,
  InstagramIcon,
  YouTubeIcon,
  TiktokIcon,
  StudioDesignSystem,
  PolymeshNavbar,
} from '@ttx/design-system';
import {ExternalURL, SocialURL} from '@ttx/design-system/src/footer/types';
import {useRouter} from 'next/router';
import React, {useEffect, useRef, useState} from 'react';
import {
  MenuItem,
  useMenuState,
  ControlledMenu,
  SubMenu,
  MenuDivider,
} from '@szhsin/react-menu';
import {menuSelector, menuItemSelector} from '@szhsin/react-menu/style-utils';
import {usePlausible} from 'next-plausible';
import {useAuthenticationContext} from '../../contexts/authentication-context';
import {MusicPlayerProvider} from '../../contexts/music-player-context/music-player-context';
import {useRightSideDrawer} from '../right-side-drawer';
import {PageLayoutOptions} from './types';
import '@szhsin/react-menu/dist/core.css';
import {useAnalyticsContext} from '../../contexts/analytics-context';
import {ANALYTICS_STATE} from '../../contexts/analytics-context/utils';
import {getCookie, setCookie} from '../../server/cookies';
import {
  CONTENT_MAX_WIDTH,
  STUDIOS_PAGES,
  STUDIO_MENUS,
} from '../../utils/consts';
import {WarningModal} from './warning-modal';
import {useStudioProperties} from '../../utils/studio-util';
import {usePolymesh} from '../../contexts/polymesh-context';
import {
  LocalStroageUtil,
  POLYMESH_LOCAL_STORAGE,
} from '../../utils/local-storage-utils';

const StyledControlledMenu = styled(ControlledMenu, {
  [menuSelector.name]: {
    marginTop: '0px',
    minWidth: 220,
    background: '$surfaceDark',
    borderColor: '$outlines',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderBottomWidth: '$zero',
  },
  [menuItemSelector.name]: {
    backgroundColor: 'transparent',
    padding: '6px 12px',
  },
  [menuSelector.dirRight]: {
    marginTop: '-1px',
  },
  length: 0,
});

const StyledNavItem = styled(MenuItem, {
  backgroundColor: 'transparent',
  display: 'flex',
  length: 0,
});

const StyledMenuDivider = styled(MenuDivider, {
  backgroundColor: '$outlines',
  width: '100%',
  minHeight: '1px',
  height: '1px',
  padding: 0,
  margin: 0,
  length: 0,
});

const StyledSubNavItem = styled(SubMenu, {
  backgroundColor: 'transparent',
  length: 0,
});

const aboutMenu = [
  {text: 'About', href: '/about'},
  {
    text: 'Mission',
    items: [
      {
        text: 'Mission',
        href: '/about/mission',
      },
      {
        text: 'Documentation',
        href: '/about/mission?ref=documentation',
      },
      {
        text: 'Tech Stack',
        href: '/about/mission?ref=tech',
      },
      {
        text: 'Partners',
        href: '/about/mission?ref=partners',
      },
      {
        text: 'Team',
        href: '/about/mission?ref=team',
      },
      {
        text: 'Membership',
        href: `/about/membership`,
      },
      {
        text: 'Links',
        href: '/about/links',
      },
    ],
  },
  {
    text: 'TraxxTokens',
    href: '/about/traxxtokens',
  },
  {
    text: 'Media Hub',
    items: [
      {
        text: 'Media hub',
        href: '/about/media',
      },
      {
        text: 'News articles',
        href: '/about/media?ref=news',
      },
      {
        text: 'Press releases',
        href: '/about/media?ref=press',
      },
      {
        text: 'Assets',
        href: '/about/media?ref=assets',
      },
    ],
  },
];

const footerSocialURLs: SocialURL[] = [
  {
    socialName: 'Twitter',
    url: 'https://twitter.com/TokenTraxx',
    icon: <TwitterIcon color="textDefault" />,
  },
  {
    socialName: 'Discord',
    url: 'https://discord.com/invite/JyA7H4DyjG',
    icon: <DiscordIcon color="textDefault" />,
  },
  {
    socialName: 'Telegram',
    url: 'https://t.me/TokenTraxxMusic',
    icon: <TelegramIcon color="textDefault" />,
  },
  {
    socialName: 'Medium',
    url: 'https://medium.com/tokentraxx',
    icon: <MediumIcon color="textDefault" />,
  },
  {
    socialName: 'Instagram',
    url: 'https://www.instagram.com/tokentraxx/',
    icon: <InstagramIcon color="textDefault" />,
  },
  {
    socialName: 'TikTok',
    url: 'https://www.tiktok.com/@tokentraxx',
    icon: <TiktokIcon color="textDefault" />,
  },
  {
    socialName: 'Youtube',
    url: 'https://www.youtube.com/channel/UCoBqzOCTafhAfs1-afCesnQ',
    icon: <YouTubeIcon color="textDefault" />,
  },
];

const footerExternalURLs: ExternalURL[] = [
  {
    title: 'Terms of Use',
    url: 'https://ttdocs.gitbook.io/terms-and-conditions/',
  },
  {title: 'Privacy Policy', url: 'https://ttdocs.gitbook.io/privacy-policy/'},
  {title: 'Cookie Policy', url: 'https://ttdocs.gitbook.io/cookie-policy/'},
  {title: 'GitBook', url: 'https://docs.tokentraxx.com/'},
  {
    title: 'FAQs',
    url: 'https://ttdocs.gitbook.io/faqs/',
  },
];
export interface PageLayoutProps extends PageLayoutOptions {
  children: React.ReactElement | (React.ReactElement | null)[];
}

export const PageLayout = React.memo(
  ({
    children,
    showFooter,
    showMusicPlayer,
    showNavbar = true,
  }: PageLayoutProps) => {
    const router = useRouter();
    const {slug = ''} = router.query;
    const {themeColor, isLoading, studioContent} = useStudioProperties();
    const isStudioPage = STUDIOS_PAGES.includes(router.pathname);
    const {footerUsefulLinks, contact, homeLink} = STUDIO_MENUS[
      slug as keyof typeof STUDIO_MENUS
    ] || {
      footerUsefulLinks: footerExternalURLs,
      contact: '',
      homeLink: '/',
    };

    const {openWallet, openPurchaseHistory} = useRightSideDrawer();

    const {logout, walletProvider, walletConnected, walletAddress} =
      useAuthenticationContext();
    const ref = useRef(null);
    const plausible = usePlausible();
    const [menuProps, toggleMenu] = useMenuState({transition: false});
    const [isHovered, setIsHovered] = useState(false);
    const [showCookieBanner, setShowCookieBanner] = useState<boolean>(false);

    const logoAnimationCookie = getCookie(null, 'logoAnimation');

    const isPolymeshRoute = router.pathname.includes('/polymesh');
    const {connectWallet} = usePolymesh();

    const onOpenWalletSelect = () => {
      plausible('Connect Wallet Selected (Navbar)');
      openWallet();
    };

    useEffect(() => {
      if (!logoAnimationCookie) {
        setCookie(
          null,
          'logoAnimation',
          'logoAnimationValue',
          undefined,
          86400,
        ); // ONE DAY
      }
    }, [logoAnimationCookie]);

    const hoveredStyle = useSpring({
      rotate: isHovered ? 180 : 0,
      config: {duration: 250},
    });

    const {setCookiesResponse, analyticsState} = useAnalyticsContext();

    useEffect(() => {
      const cookieBannerTimer = setTimeout(() => {
        setShowCookieBanner(
          analyticsState !== ANALYTICS_STATE?.CONSENT &&
            analyticsState !== ANALYTICS_STATE?.DISALLOW,
        );
      }, 3500);
      return () => {
        clearTimeout(cookieBannerTimer);
      };
    }, [analyticsState]);

    useEffect(() => {
      if (isHovered) {
        toggleMenu(true);
      } else {
        toggleMenu(false);
      }
    }, [isHovered, toggleMenu]);

    const defaultNavbarItems: (NavbarItem | React.ReactNode)[] = [
      {
        href: `/discover`,
        subRoutes: ['/discover', '/artists'],
        text: 'Marketplace',
        handleHover: () => setIsHovered(false),
      },
      {
        href: `/protocol`,
        text: 'TRAXX Studios',
        subRoutes: ['/protocol'],
        handleHover: () => setIsHovered(false),
      },
      {
        href: `/advisory`,
        text: 'Advisory',
        subRoutes: ['/advisory'],
        handleHover: () => setIsHovered(false),
      },
      {
        href: `/about`,
        text: 'About',
        subRoutes: ['/about'],
        handleHover: () => setIsHovered(false),
      },
      {
        href: `/polymesh/artists`,
        text: 'Polymesh',
        subRoutes: ['/polymesh/artists'],
        handleHover: () => setIsHovered(false),
      },
      // {
      //   href: '/discover',
      //   text: 'Discover',
      //   handleHover: () => setIsHovered(false),
      // },
      // {
      //   href: '/artists',
      //   text: 'Artists',
      //   handleHover: () => setIsHovered(false),
      // },
      // <Box height="100%" cursor="pointer" key="about-menu">
      //   <Box
      //     ref={ref}
      //     paddingLeft="three"
      //     paddingRight="one"
      //     flexDirection="row"
      //     alignItems="center"
      //     onHoverIn={() => {
      //       // if (router.asPath !== '/') setIsHovered(true);
      //     }}
      //     height="100%"
      //   >
      //     <Typography color="textDefault" textStyle="s">
      //       About
      //     </Typography>
      //     {/* <AnimatedBox marginLeft="one" style={hoveredStyle}>
      //       <ArrowDownIcon
      //         color={router.asPath.includes('/about') ? 'outlines' : 'textLow'}
      //       />
      //     </AnimatedBox> */}
      //   </Box>
      //   <StyledControlledMenu
      //     {...menuProps}
      //     anchorRef={ref}
      //     onMouseEnter={() => setIsHovered(true)}
      //     onMouseLeave={() => setIsHovered(false)}
      //     onClose={() => toggleMenu(true)}
      //   >
      //     {aboutMenu.map((item) => {
      //       if (item.items) {
      //         return (
      //           <React.Fragment key={`${item.text}-main`}>
      //             <StyledSubNavItem
      //               key={`${item.text}-main`}
      //               label={
      //                 <TransparentButton onPress={() => null}>
      //                   <Box
      //                     flexDirection="row"
      //                     justifyContent="space-between"
      //                     alignItems="center"
      //                   >
      //                     <Typography color="textDefault" textStyle="s">
      //                       {item.text}
      //                     </Typography>
      //                     <ArrowRightIcon size={14} color="textDefault" />
      //                   </Box>
      //                 </TransparentButton>
      //               }
      //             >
      //               {item.items.map((subItem) => (
      //                 // Required to line up the sub nav items
      //                 <Box maxHeight="31px" key={`${subItem.text}-sub`}>
      //                   <StyledNavItem>
      //                     <FrameworkLink href={subItem.href || ''}>
      //                       <TransparentButton onPress={() => null}>
      //                         <Typography color="textDefault" textStyle="s">
      //                           {subItem.text}
      //                         </Typography>
      //                       </TransparentButton>
      //                     </FrameworkLink>
      //                   </StyledNavItem>
      //                   <StyledMenuDivider />
      //                 </Box>
      //               ))}
      //             </StyledSubNavItem>
      //             <StyledMenuDivider />
      //           </React.Fragment>
      //         );
      //       }
      //       return (
      //         <React.Fragment key={item.text}>
      //           <StyledNavItem>
      //             <FrameworkLink href={item.href}>
      //               <TransparentButton onPress={() => null}>
      //                 <Typography color="textDefault" textStyle="s">
      //                   {item.text}
      //                 </Typography>
      //               </TransparentButton>
      //             </FrameworkLink>
      //           </StyledNavItem>
      //           <StyledMenuDivider />
      //         </React.Fragment>
      //       );
      //     })}
      //   </StyledControlledMenu>
      // </Box>,
    ];

    const navbarItems = walletProvider
      ? [
          ...defaultNavbarItems,
          ...(walletProvider !== 'zilPay' && walletConnected
            ? [
                {
                  href: '/my-nfts',
                  text: 'My NFTs',
                  subRoutes: ['/my-nfts'],
                  handleHover: () => setIsHovered(false),
                },
              ]
            : []),
        ]
      : defaultNavbarItems;

    let NavbarComponent;

    if (isStudioPage) {
      NavbarComponent = (
        <StudioDesignSystem.Navbar
          homePageHref={homeLink}
          items={navbarItems}
          aboutMenuItems={aboutMenu}
          currentHref={router.asPath}
          onLogoutPress={walletConnected ? logout : undefined}
          walletConnected={walletConnected && !!walletAddress}
          openWallet={onOpenWalletSelect}
          openPurchaseHistory={openPurchaseHistory}
          runLogoAnimation={!logoAnimationCookie}
          studioSlug={slug as string}
          themeColor={themeColor}
          isLoading={isLoading}
          navLinks={studioContent.navLinks}
        />
      );
    } else if (isPolymeshRoute) {
      NavbarComponent = (
        <PolymeshNavbar
          homePageHref="/"
          items={[]}
          aboutMenuItems={[]}
          currentHref={router.asPath}
          onLogoutPress={undefined}
          walletConnected={false}
          openWallet={async () => {
            await connectWallet();
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          openPurchaseHistory={() => {}}
          runLogoAnimation={false}
          studioSlug=""
        />
      );
    } else {
      NavbarComponent = (
        <Navbar
          homePageHref="/"
          items={navbarItems}
          aboutMenuItems={aboutMenu}
          currentHref={router.asPath}
          onLogoutPress={walletConnected ? logout : undefined}
          walletConnected={walletConnected && !!walletAddress}
          openWallet={onOpenWalletSelect}
          openPurchaseHistory={openPurchaseHistory}
          runLogoAnimation={!logoAnimationCookie}
          studioSlug={slug as string}
        />
      );
    }

    return (
      <Box
        width="100%"
        style={isStudioPage ? {maxWidth: '1040px', margin: 'auto'} : {}}
        height="100%"
      >
        <MusicPlayerProvider showMusicPlayer={showMusicPlayer}>
          <Box
            position="absolute"
            left="zero"
            right="zero"
            top="zero"
            zIndex={1}
          >
            {showNavbar ? <>{NavbarComponent}</> : null}
            {showCookieBanner ? (
              <CookieBanner
                privacyPolicyURL="https://ttdocs.gitbook.io/privacy-policy/"
                onAcceptPress={() => {
                  setCookiesResponse(ANALYTICS_STATE?.CONSENT || null);
                  setShowCookieBanner(false);
                }}
                onClosePress={() => {
                  setCookiesResponse(ANALYTICS_STATE.DISALLOW);
                  setShowCookieBanner(false);
                }}
                visible={showCookieBanner}
              />
            ) : null}
          </Box>
          <Box
            alignItems="center"
            justifyContent="flex-start"
            overflow="scroll"
            height="100%"
          >
            {showNavbar ? (
              <Box height={[isStudioPage ? 60 : 44, 30, 70]} width="100%" />
            ) : null}
            <Box
              minHeight={['calc(100vh - 44px)', 'calc(100vh - 30px)']}
              width="100%"
              maxWidth={CONTENT_MAX_WIDTH}
              justifyContent="space-between"
              borderColor="grey24"
              borderStyle="solid"
              borderTopWidth="zero"
              borderWidth={isStudioPage ? 'zero' : 'one'}
            >
              <Box maxWidth={CONTENT_MAX_WIDTH} width="100%">
                {children}
              </Box>
              {showFooter ? (
                <>
                  {isStudioPage ? (
                    <Box width="100%" zIndex={-1}>
                      <StudioDesignSystem.Footer
                        footerExternalLinks={footerUsefulLinks}
                        footerSocialLinks={studioContent.footerSocialURLs}
                        contact={studioContent.contact}
                        isLoading={isLoading}
                      />
                    </Box>
                  ) : (
                    <Box width="100%" zIndex={-1}>
                      <Footer
                        footerExternalLinks={footerExternalURLs}
                        footerSocialLinks={footerSocialURLs}
                        contact="contact@tokentraxx.com"
                      />
                    </Box>
                  )}
                </>
              ) : null}
              {showMusicPlayer ? (
                <VisibilityBox show={[false, false, true]}>
                  <Box height={75} width="100%" />
                </VisibilityBox>
              ) : null}
            </Box>
          </Box>
        </MusicPlayerProvider>
      </Box>
    );
  },
);
