/* eslint-disable no-new */
/* eslint-disable no-shadow */
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {usePlausible} from 'next-plausible';
import {
  Address,
  getPublicKeyFromMessageAndSignature,
  PaperProvider,
  VenlyChains,
  VenlyProvider,
  Web3Provider,
  ZilPayProvider,
} from '@ttx/core';
import {useWeb3React} from '@web3-react/core';
import {useBreakpoint} from '@ttx/design-system/src/use-breakpoint';
import {useTtxMutation, useTtxQuery} from '../../hooks/ttx-trpc';
import {useToastContext} from '../toast-context';
import {AUTHENTICATION_MESSAGE_TO_SIGN} from './consts';
import {
  AuthenticationContextValue,
  RegisterOrLoginOptions,
  WalletNetwork,
  WalletRegistrationSource,
  SignMessageResult,
  RegisterOrLoginServer,
  Network,
} from './types';
import {
  getAuthenticatedWalletState,
  networkNameToSymbol,
  getNetworkFromChainId,
  getFormattedChainIdErrorMessage,
  getDeeplinkUrl,
  getEVMChainBalance,
  getAuthenticatedSignatureState,
  getAuthenticatedWalletIdState,
  getAuthenticatedWalletInfoState,
  clearAuthenticatedWalletInfoState,
} from './utils';
import {ttxQueryClient} from '../token-traxx-query-provider';
import {getConnectorForWalletRegistrationSource} from './connectors';
import {useCachedState} from '../../hooks/use-cached-state';
import {mixpanelIdentifyUser} from '../analytics-context/utils';
import {VENLY_NETWORK} from '../../utils/consts';
import {
  LocalStroageUtil,
  LOCAL_STORAGE_KEY,
} from '../../utils/local-storage-utils';
import {removeSessionItem} from '../../utils/session-storage-util';
import {studioProperties} from '../../utils/studio-util';

const AuthenticationContext =
  React.createContext<AuthenticationContextValue | null>(null);

export const AuthenticationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isActivateConnectorPromiseResolving = useRef(false);
  const web3React =
    useWeb3React<
      Promise<Web3Provider | ZilPayProvider | VenlyProvider | PaperProvider>
    >();
  const [isSigningMessage, setIsSigningMessage] = useState(false);
  const waitingForSignatureToastIdRef = useRef<number | null>(null);

  const authenticatedWalletProvider = useRef<string | null>(null);
  const authenticatedWalletAddress = useRef<string | null>(null);
  const authenticatedWalletChain = useRef<number | string | null>(null);
  const authenticatedUserId = useRef<string | null>(null);

  const {currentBreakpoint} = useBreakpoint();
  const isMobile = currentBreakpoint === '768px';

  const [walletProvider, setWalletProvider] =
    useState<WalletRegistrationSource | null>(null);
  const walletAddress: Address | null = (web3React.account as Address) ?? null;
  const walletChain: number | null = web3React.chainId ?? null;

  const toast = useToastContext();
  const plausible = usePlausible();
  const {isStudioPage = false} = studioProperties();

  const {
    mutateAsync: registerOrLoginMutation,
    isLoading: isRegisterOrLoginLoading,
  } = useTtxMutation('auth.registerOrLogin');

  const {mutateAsync: logoutMutation} = useTtxMutation('auth.logout');

  const {data: networksData} = useTtxQuery(['traxx.getNetworks']);

  const isConnectedToValidChainId = useMemo(
    () =>
      walletChain && networksData
        ? networksData
            ?.map((network) => network.chainId as number)
            .includes(walletChain)
        : null,
    [networksData, walletChain],
  );

  const walletNetwork = useMemo((): WalletNetwork | null => {
    if (!web3React.chainId) return null;
    if (!walletProvider) return null;

    return getNetworkFromChainId(web3React.chainId, walletProvider);
  }, [walletProvider, web3React.chainId]);

  const walletRPC = useMemo(
    () =>
      walletChain && networksData
        ? networksData?.find(
            (network) => (network.chainId as number) === walletChain,
          )?.rpcUrl
        : null,
    [networksData, walletChain],
  );

  const walletNativeCurrencySymbol = useMemo(
    () => (walletNetwork ? networkNameToSymbol(walletNetwork) : null),
    [walletNetwork],
  );

  const updateNativeCurrencyBalance = useCallback(async () => {
    if (!web3React.account) return null;
    const loadedLibrary = await web3React.library;
    if (!loadedLibrary) return null;

    let balance = null;
    switch (walletProvider) {
      case 'venly': {
        balance = walletRPC
          ? await getEVMChainBalance(
              web3React.account as Address,
              walletRPC as string,
            )
          : null;
        break;
      }
      default: {
        balance = await loadedLibrary.getBalance(web3React.account);
        break;
      }
    }

    return balance ? balance.toString() : null;
  }, [web3React.account, web3React.library, walletProvider, walletRPC]);

  const walletNativeCurrencyBalance = useCachedState(
    updateNativeCurrencyBalance,
    null,
  );

  const signMessage = useCallback(
    async (
      nonce: string,
      source: WalletRegistrationSource,
      network = 'ETHEREUM',
      walletNetwork: WalletNetwork | null,
    ) => {
      const loadedLibrary = await web3React.library;
      if (!loadedLibrary) throw new Error('No wallet library is loaded.');
      const {account} = web3React;
      if (!account) throw new Error('No account is connected.');

      let opts = nonce;
      if (source === 'venly') {
        if (!getAuthenticatedWalletIdState())
          throw new Error('Wallet Id not exists.');
        opts = JSON.stringify({
          walletId: getAuthenticatedWalletIdState(),
          secretType: network,
          data: nonce,
          walletAddress,
          walletNetwork,
          walletChain,
        });
      }
      const requestSignature = async () => {
        const result = await loadedLibrary.getSigner(account).signMessage(opts);

        return typeof result === 'string'
          ? {
              signature: result,
              publicKey: getPublicKeyFromMessageAndSignature(nonce, result),
            }
          : {
              signature: result.signature,
              publicKey: result.publicKey,
            };
      };

      return new Promise<SignMessageResult>((resolve, reject) => {
        /**
         * If we request a signature too quickly after asking the MetaMask mobile app
         * to activate the wallet, the mobile app will freeze (sometimes)... So we
         * wait a bit before requesting a signature.
         */
        setTimeout(() => requestSignature().then(resolve).catch(reject), 250);
      });
    },
    [web3React],
  );

  const connectWallet = useCallback(
    // eslint-disable-next-line consistent-return
    async (
      registrationSource: WalletRegistrationSource,
      network = 'MATIC' as Network,
      isSwitched = false,
    ) => {
      if (!window.ethereum && registrationSource === 'metaMask') {
        if (isMobile) {
          window?.open(getDeeplinkUrl(isMobile))?.focus();
        } else {
          window?.open('https://metamask.io/download/', '_blank')?.focus();
        }
      } else {
        const connectorFromWalletRegistrationSource =
          await getConnectorForWalletRegistrationSource(
            registrationSource,
            network,
            isSwitched,
          );
        /**
         * We sometimes get a "stale connector". To avoid this, we add an arbitrary
         * time to wait. This is a hack, but it appears to work...
         */
        return new Promise<void>((resolve, reject) => {
          isActivateConnectorPromiseResolving.current = true;
          setTimeout(async () => {
            try {
              // The web3React promise doesn't appear to resolve...
              await web3React.activate(
                connectorFromWalletRegistrationSource,
                (e) => {
                  if (e && e.message) toast.addWarningToast(e.message);
                },
              );
              setWalletProvider(registrationSource);
              plausible('Connect Wallet');
              if (walletProvider === 'venly') {
                plausible('Venly Wallet Connected');
              }
              if (walletProvider === 'paper') {
                plausible('Paper Wallet Connected');
              }
              if (walletProvider === 'metaMask') {
                plausible('Metamask Wallet Connected');
              }
              resolve();
            } catch (e) {
              reject(e);
            } finally {
              isActivateConnectorPromiseResolving.current = false;
            }
          }, 500);
        });
      }
      const connectorFromWalletRegistrationSource =
        await getConnectorForWalletRegistrationSource(
          registrationSource,
          network,
          isSwitched,
        );
      /**
       * We sometimes get a "stale connector". To avoid this, we add an arbitrary
       * time to wait. This is a hack, but it appears to work...
       */
      return new Promise<void>((resolve, reject) => {
        isActivateConnectorPromiseResolving.current = true;
        setTimeout(async () => {
          try {
            // The web3React promise doesn't appear to resolve...
            await web3React.activate(connectorFromWalletRegistrationSource);
            setWalletProvider(registrationSource);
            plausible('Connect Wallet');
            resolve();
          } catch (e) {
            reject(e);
          } finally {
            isActivateConnectorPromiseResolving.current = false;
          }
        }, 500);
      });
    },
    [web3React, plausible],
  );

  const disconnectWallet = useCallback(() => {
    removeSessionItem('PICKED_NFT');
    LocalStroageUtil.removeItem(LOCAL_STORAGE_KEY.TRACK_NETWORK);
    web3React.deactivate();
    plausible('Disconnect Wallet');
    isActivateConnectorPromiseResolving.current = false;

    if (!web3React.connector) return;

    const untypedWeb3ReactConnector = web3React.connector as any;

    if (typeof untypedWeb3ReactConnector.close !== 'function') return;

    untypedWeb3ReactConnector.close();
  }, [web3React, plausible]);

  const registerOrLoginServer = async ({
    signedMessage,
    walletAddress,
    walletNetwork,
    walletChain,
    walletName,
    registrationSource,
    walletProvider,
    nonce,
  }: RegisterOrLoginServer) => {
    if (getAuthenticatedWalletInfoState()) {
      clearAuthenticatedWalletInfoState();
    }

    const {
      walletState: authenticatedWalletState,
      userProfile: authenticatedUserProfile,
    } = await registerOrLoginMutation({
      network: walletNetwork,
      publicAddress: walletAddress,
      signature: signedMessage.signature,
      publicKey: signedMessage.publicKey,
      chainId: walletChain,
      registrationSourceAdditionalData: walletName ?? registrationSource,
      registrationSource: walletProvider,
      message: nonce,
      roles: ['collector'],
    });

    ttxQueryClient.clear();

    authenticatedWalletProvider.current = authenticatedWalletState.provider;
    authenticatedWalletAddress.current = authenticatedWalletState.address;
    authenticatedWalletChain.current = authenticatedWalletState.chainId;
    authenticatedUserId.current = authenticatedUserProfile._id;

    toast.addSuccessToast();
  };

  const registerOrLogin = useCallback(
    async ({registrationSource, walletName}: RegisterOrLoginOptions) => {
      setIsSigningMessage(true);
      try {
        if (registrationSource !== 'paper') {
          waitingForSignatureToastIdRef.current = toast.addInfoToast(
            `Waiting for your wallet to sign the message...`,
            {
              title: `Waiting for ${walletProvider}`,
              timeout: 0,
              onClose: () => {
                setIsSigningMessage(false);
                plausible(
                  'Connect Wallet Unsuccessful : User deny the wallet signature ',
                );
                toast.addWarningToast(
                  'You cancelled the message signing request',
                );
              },
            },
          );
        }
        const network =
          VENLY_NETWORK[walletNetwork as keyof typeof walletNetwork];
        const nonce = `${AUTHENTICATION_MESSAGE_TO_SIGN} @ ${new Date().toLocaleString()}`;
        let signedMessage: any;
        if (registrationSource === 'paper') {
          signedMessage = {
            signature: getAuthenticatedSignatureState(),
            publicKey: getPublicKeyFromMessageAndSignature(
              nonce,
              getAuthenticatedSignatureState() as string,
            ),
          };
        } else {
          signedMessage = await signMessage(
            nonce,
            registrationSource,
            network,
            walletNetwork,
          );
        }

        setIsSigningMessage(false);

        if (!walletChain) throw new Error('Missing chainId');
        if (!walletAddress) throw new Error('Missing account');
        if (!walletProvider) throw new Error('Missing provider');
        if (!walletNetwork) throw new Error('Missing network');

        await registerOrLoginServer({
          signedMessage,
          walletNetwork,
          walletAddress,
          walletChain,
          walletName,
          walletProvider,
          nonce,
          registrationSource,
        });
      } catch (e) {
        plausible('Connect Wallet Unsuccessful : deny the wallet signature ');
        toast.addErrorToast((e as any).message.toString());
        setIsSigningMessage(false);
        await logoutMutation();
        disconnectWallet();
      }
    },
    [
      toast,
      walletProvider,
      signMessage,
      walletChain,
      walletAddress,
      walletNetwork,
      registerOrLoginMutation,
      logoutMutation,
      disconnectWallet,
    ],
  );

  const logout = useCallback(async () => {
    await logoutMutation();

    toast.addSuccessToast('Signed out');

    disconnectWallet();
  }, [toast, logoutMutation, disconnectWallet]);

  const clearWalletState = useCallback(() => {
    setWalletProvider(null);
  }, []);

  useEffect(() => {
    const formattedError = getFormattedChainIdErrorMessage(
      networksData?.map((network) => network.displayName),
      web3React?.error?.message,
    );
    if (
      web3React.error?.name === 'UnsupportedChainIdError' ||
      (web3React.active && walletChain && !isConnectedToValidChainId) ||
      web3React.error
    ) {
      if (formattedError) {
        toast.addWarningToast(formattedError);
        logoutMutation();
        disconnectWallet();
        if (networksData) {
          const networkDetails =
            networksData.find((e) => e.isActive) ||
            networksData[networksData.length - 1];
          window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${networkDetails?.chainId.toString(16)}`,
                chainName: networkDetails?.displayName,
                rpcUrls: [networkDetails?.rpcUrl],
                nativeCurrency: {
                  name: networkDetails.network === 'polygon' ? 'Matic' : 'Eth',
                  symbol:
                    networkDetails.network === 'polygon' ? 'MATIC' : 'ETH',
                  decimals: 18,
                },
              },
            ],
          });
        }
      }
    }
  }, [
    disconnectWallet,
    logoutMutation,
    isConnectedToValidChainId,
    networksData,
    toast,
    walletChain,
    web3React.active,
    web3React.error,
  ]);

  /**
   * When we have connected a wallet, register with the API
   */
  const isLoggingInRef = useRef(false);
  useEffect(() => {
    const run = async () => {
      const {chainId, address, provider} = getAuthenticatedWalletState() || {
        chainId: undefined,
        address: undefined,
        provider: undefined,
        userId: undefined,
      };

      const hasChangedWalletState =
        chainId !== walletChain ||
        address !== walletAddress ||
        provider !== walletProvider;

      const isLoggedIn = !!getAuthenticatedWalletState();

      if (
        (!isLoggedIn || hasChangedWalletState) &&
        !isLoggingInRef.current &&
        walletProvider &&
        isConnectedToValidChainId
      ) {
        isLoggingInRef.current = true;
        try {
          await registerOrLogin({registrationSource: walletProvider});
          mixpanelIdentifyUser(walletAddress, walletProvider);
        } catch (error) {
          /* handled by `registerOrLogin` */
        } finally {
          isLoggingInRef.current = false;
        }
      }
    };
    run();
  }, [
    isConnectedToValidChainId,
    logoutMutation,
    registerOrLogin,
    walletAddress,
    walletChain,
    walletProvider,
  ]);

  /**
   * When we have disconnected the wallet, clear the wallet state
   */
  useEffect(() => {
    if (web3React.active) return;
    clearWalletState();
  }, [clearWalletState, web3React.active]);

  useEffect(() => {
    if (isSigningMessage) return;
    if (!waitingForSignatureToastIdRef.current) return;
    toast.removeToast(waitingForSignatureToastIdRef.current);
  }, [isSigningMessage, toast]);

  const authenticatedWalletState = getAuthenticatedWalletState();
  /**
   * If we have wallet state in the cookies, connect to the wallet automatically
   */
  useEffect(() => {
    if (web3React.active) return;
    if (!authenticatedWalletState) return;
    connectWallet(authenticatedWalletState.provider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3React.active, !!authenticatedWalletState]);

  /**
   * When the hook mounts:
   *    - Load the wallet state from local storage into an initial state and the walletState
   */
  useEffect(() => {
    if (!authenticatedWalletState) return;
    authenticatedWalletAddress.current =
      authenticatedWalletState.address ?? null;
    authenticatedWalletProvider.current =
      authenticatedWalletState.provider ?? null;
    authenticatedWalletChain.current = authenticatedWalletState.chainId ?? null;
    authenticatedUserId.current = authenticatedWalletState.userId ?? null;

    setWalletProvider(authenticatedWalletState.provider);
  }, []);

  useEffect(() => {
    const authenticationWalletInfo = getAuthenticatedWalletInfoState();
    if (walletProvider !== 'venly') return;
    const signResult = new URLSearchParams(window.location.search);
    const isSignSuccess =
      signResult.get('status') === 'SUCCESS' &&
      signResult.get('r') &&
      signResult.get('s') &&
      signResult.get('v') &&
      signResult.get('signature');
    const isSignAborted =
      signResult.get('status') === 'ABORTED' && signResult.get('cid');
    const isExportWallet =
      signResult.get('status') === 'SUCCESS' && signResult.get('cid');
    const isImportWallet =
      signResult.get('status') === 'SUCCESS' && signResult.get('walletId');

    let updateRoute = false;
    const importExportAction = isImportWallet || isExportWallet;
    if (window.location.hash || importExportAction) {
      updateRoute = !!importExportAction;
      const lsNetwork = LocalStroageUtil.getItem(
        LOCAL_STORAGE_KEY.TRACK_NETWORK,
      );

      const network =
        (window.location.pathname.includes('/tracks') ||
          window.location.pathname.includes('/membership-card') ||
          isStudioPage) &&
        lsNetwork
          ? lsNetwork
          : 'ETHEREUM';
      const previousNetwork =
        authenticationWalletInfo?.walletNetwork &&
        network &&
        VENLY_NETWORK[
          authenticationWalletInfo?.walletNetwork as keyof typeof VENLY_NETWORK
        ];
      connectWallet(
        'venly',
        network as Network,
        window.location.pathname.includes('/tracks') &&
          previousNetwork !== network,
      );
    }
    if (isSignAborted) {
      updateRoute = true;
      toast.addErrorToast('User denied the request.');
    }
    if (authenticationWalletInfo && isSignSuccess) {
      LocalStroageUtil.removeItem(LOCAL_STORAGE_KEY.TRACK_NETWORK);
      updateRoute = true;
      const {nonce, walletAddress, walletChain, walletNetwork} =
        authenticationWalletInfo;
      const sig = {
        signature: signResult.get('signature'),
        publicKey: getPublicKeyFromMessageAndSignature(
          nonce,
          signResult.get('signature') as string,
        ),
      };
      const register = async () => {
        await registerOrLoginServer({
          nonce,
          walletAddress,
          walletChain,
          walletNetwork,
          walletName: 'venly',
          registrationSource: 'venly',
          walletProvider: 'venly',
          signedMessage: sig as SignMessageResult,
        });
      };
      register();
    }
    if (updateRoute) {
      updateRoute = false;
      new Promise(() =>
        setTimeout(() => {
          window.history.replaceState(
            null,
            '',
            `${window.location.origin}${window.location.pathname}`,
          );
        }, 100),
      );
    }
  }, []);

  const walletConnected = !!getAuthenticatedWalletState() || web3React.active;

  const updateFromLifi = async () => {
    const {chainId, address, provider} = getAuthenticatedWalletState() || {
      chainId: undefined,
      address: undefined,
      provider: undefined,
      userId: undefined,
    };

    if (
      !chainId ||
      !address ||
      ![1, 137].includes(chainId) ||
      provider !== 'metaMask'
    )
      return;

    const connectedNetwork = chainId === 1 ? 'ETHEREUM' : 'MATIC';

    const connectorFromWalletRegistrationSource =
      await getConnectorForWalletRegistrationSource(
        'metaMask',
        connectedNetwork,
        false,
      );
    try {
      // The web3React promise doesn't appear to resolve...
      setWalletProvider('metaMask');
      await web3React.activate(connectorFromWalletRegistrationSource);
    } catch (e) {}
  };

  const value = useMemo(
    (): AuthenticationContextValue => ({
      isRegisterOrLoginLoading: isRegisterOrLoginLoading || isSigningMessage,
      // This is probably obsolete...
      isAuthenticated: () => getAuthenticatedWalletState() !== null,
      logout,
      userId: authenticatedUserId.current,
      walletAddress,
      walletChain,
      walletProvider,
      walletConnected,
      walletNetwork,
      walletNativeCurrencySymbol,
      walletNativeCurrencyBalance,
      connectWallet,
      disconnectWallet,
      _web3React: web3React,
      isConnectedToValidChainId,
      authenticatedChainId: getAuthenticatedWalletState()?.chainId,
      networksData,
      updateFromLifi,
    }),
    [
      connectWallet,
      disconnectWallet,
      isConnectedToValidChainId,
      isRegisterOrLoginLoading,
      walletConnected,
      isSigningMessage,
      logout,
      walletAddress,
      walletChain,
      walletNativeCurrencyBalance,
      walletNativeCurrencySymbol,
      walletNetwork,
      walletProvider,
      web3React,
      networksData,
      updateFromLifi,
    ],
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = (): AuthenticationContextValue => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      'useAuthentication must be used within an AuthenticationContextProvider',
    );
  }

  return context;
};
