import {toNativeCurrencyString} from '@ttx/core';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Modal, WalletContents} from '@ttx/design-system';
import {useAuthenticationContext} from '../../../contexts/authentication-context';
import {useSmartContractContext} from '../../../contexts/smart-contract/context';
import {networkNameToDisplayName, formatAddress} from '../utils';
import {RightSideDrawerModal} from '../right-side-drawer-modal';
import {SignUpInModal} from '../../purchase-or-download-cta/cta-modals/signup-in-modal';
import {PaymentType} from '../../wallet-connection-button-group';

interface WalletProps {
  isModalMounted: boolean;
  isModalShowing: boolean;
  setIsModalShowing: (arg0: boolean) => void;
  onClosePress: () => void;
  onDrawerCloseAnimationFinish: () => void;
}

export const Wallet: React.FC<WalletProps> = ({
  isModalMounted,
  isModalShowing,
  onClosePress,
  onDrawerCloseAnimationFinish,
  setIsModalShowing,
}) => {
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);

  const {
    logout,
    walletConnected,
    walletAddress,
    walletNetwork,
    walletNativeCurrencyBalance,
    walletNativeCurrencySymbol,
  } = useAuthenticationContext();

  const {supportedPaymentTokenBalances} = useSmartContractContext();

  const balances = useMemo(
    () => [
      ...(walletNetwork && walletNativeCurrencySymbol
        ? [
            {
              currencySymbol: walletNativeCurrencySymbol,
              currencyName: networkNameToDisplayName(walletNetwork),
              formattedValue: walletNativeCurrencyBalance
                ? toNativeCurrencyString(
                    walletNativeCurrencyBalance,
                    walletNativeCurrencySymbol,
                  )
                : null,
            },
          ]
        : []),
      ...supportedPaymentTokenBalances.map(({balance, symbol, name}) => ({
        currencySymbol: symbol,
        currencyName: name,
        formattedValue:
          balance !== null ? toNativeCurrencyString(balance, symbol) : null,
      })),
    ],
    [
      supportedPaymentTokenBalances,
      walletNativeCurrencyBalance,
      walletNativeCurrencySymbol,
      walletNetwork,
    ],
  );

  const handleLogoutPress = useCallback(() => {
    logout();
    onClosePress();
  }, [onClosePress, logout]);

  const handleReconnectWallet = () => {
    setIsModalShowing(false);
    setShowSecondaryModal(true);
  };

  useEffect(() => {
    if (walletConnected) setShowSecondaryModal(false);
  }, [walletConnected]);

  return (
    <>
      {isModalMounted ? (
        <RightSideDrawerModal
          isModalShowing={isModalShowing}
          onClosePress={onClosePress}
          onDrawerCloseAnimationFinish={onDrawerCloseAnimationFinish}
        >
          <WalletContents
            onLogoutPress={walletConnected ? handleLogoutPress : undefined}
            onClose={onClosePress}
            onReconnectWallet={handleReconnectWallet}
            active={walletConnected}
            formattedWalletAddress={
              walletAddress ? formatAddress(walletAddress) : null
            }
            balances={balances}
          />
        </RightSideDrawerModal>
      ) : null}
      {showSecondaryModal ? (
        <Modal
          visible={showSecondaryModal}
          onModalClose={() => setShowSecondaryModal(false)}
          closeOnOverlay
          __useReactNativeModal={false}
        >
          <Box
            flex={1}
            w="100%"
            alignItems="center"
            justifyContent="center"
            paddingX="two"
          >
            <SignUpInModal
              onClose={() => setShowSecondaryModal(false)}
              network="ETHEREUM"
              paymentType={PaymentType.Cash}
            />
          </Box>
        </Modal>
      ) : null}
    </>
  );
};
