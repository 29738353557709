import React from 'react';
import {colors, untypedColors} from '../theme/tokens/colors';

export interface WithpaperIconProps {
  color?: keyof typeof colors | (string & Record<string, never>);
  size?: number;
}

export const WithpaperIcon = React.memo<WithpaperIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 7.07674C20 6.64095 19.7178 6.25534 19.3024 6.12356L0 0V9.14815L11.4286 12.6222L2.14286 19V26.7407L4.31239 28.986L2.46932 30.6655C2.26137 30.855 2.14286 31.1233 2.14286 31.4046V38L8.57143 33.0741V25.3333L6.64797 23.6691L20 15.4815V7.07674Z"
        fill={untypedColors[color] || color}
      />
      <path
        d="M20 7.07674C20 6.64095 19.7178 6.25534 19.3024 6.12356L0 0V9.14815L20 15.4815V7.07674Z"
        fill={untypedColors[color] || color}
      />
      <path
        d="M8.57143 25.3333L2.14286 19V26.7407L8.57143 33.0741V25.3333Z"
        fill={untypedColors[color] || color}
      />
    </svg>
  ),
);
